<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-27 15:45:24
 * @LastEditTime: 2024-04-08 21:14:58
 * @FilePath: \awx-ui\src\pages\appManage\addApp\appModal\appIcon\index.vue
-->
<template>
  <div class="app-icon">
    <a-radio-group v-model="value" @change="onChange">
      <a-radio-button value="3">在线图标</a-radio-button>
      <a-radio-button value="2">纯色图标</a-radio-button>
      <a-radio-button value="1">系統图标库</a-radio-button>
    </a-radio-group>
    <SystemIcon v-show="value === '1'" :appDetail="appDetail" @getIcon="getIcon" />
    <SolidColorIcon  v-show="value === '2'" :appDetail="appDetail" @getIcon="getIcon" :value="value" :name="name" />
    <NetWorkIcon v-show="value === '3'" :appDetail="appDetail" @getIcon="getIcon" />
  </div>
</template>
<script>
import SystemIcon from './systemIcon.vue'
import NetWorkIcon from './netWorkIcon.vue'
import SolidColorIcon from './solidIcon.vue'
export default {
  data() {
    return {
      value: "3",
    };
  },
  watch: {
    'appDetail.icon': {
      handler(val) {
        if (!val) return this.value = '3'
        if (val && val.charAt(0) === '#') {
          this.value = '2'
        } else if (val && val.includes('network-')) { 
          this.value = '3'
        } else {
          this.value = '1'
        }
      },
      immediate: true
    }
  },
  props: {
    appDetail: Object,
    name: String
  },
  components: {SystemIcon, SolidColorIcon, NetWorkIcon},
  methods: {
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    onChange(e) {
      console.log(`checked = ${e.target.value}`);
    },
    getIcon (icon) {
      this.$emit('getIcon', icon, this.value)
    }
  },
};
</script>
<style lang="less" scoped>
.app-icon {
  /deep/ .ant-radio-group {
    width: 100%;
    label {
      width: 33.33333%;
      display: inline-block;
      text-align: center;
    }
  }
}
</style>