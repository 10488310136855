<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-27 16:03:30
 * @LastEditTime: 2023-12-15 14:33:19
 * @FilePath: \awx-ui\src\pages\appManage\addApp\appModal\appIcon\systemIcon.vue
-->
<template>
  <div class="system-icon">
    <a-input ref="userNameInput" v-model="iconName" @pressEnter="onSearch" placeholder="搜索图标关键字">
      <svg-icon icon-class="search" class="app-search" slot="prefix"></svg-icon>
    </a-input>
    <div class="icon-warp">
      <div v-for="(item, index) in iocnList" :key="index" class="icon-item" @click="getIcon(item)">
        <svg-icon v-if="item.Path === IconPath" class="icon-selected" icon-class="app-installed" />
        <img :src="item.Path" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconName: "",
      iocnList: [],
      IconPath: ''
    };
  },
  props: {
    appDetail: Object
  },
  components: {},
  mounted() {
    this.getAppIcon();
  },
  methods: {
    onSearch() {
      this.getAppIcon()
    },
    getIcon (item) {
      this.IconPath = item.Path
      this.$emit('getIcon', item.Path, '1')
    },
    getAppIcon() {
      let params = {
        keyword: this.iconName,
      };
      this.$axiosGet(global.API.getIcons, { ...params }).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.iocnList = res.data.results || [];
          this.IconPath = this.appDetail?.icon || ''
        }
      });
    },
  },
};
</script>
<style scoped lang='less'>
.system-icon {
  margin-top: 10px;
  .icon-warp {
    width: 100%;
    height: 172px;
    border: 1px dashed rgba(196,202,211,1);
    border-top: none;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
    padding: 16px 24px;
    display: flex;
    .icon-item {
      position: relative;
      cursor: pointer;
      height: 40px;
      margin: 0px 12px 0 0;
      width: 40px;
      min-width: 40px;
      text-align: center;
      line-height: 40px;
      background: #FFF; // 这个是图标的颜色定义
      border-radius: 6px;
      display: flex;
      box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
      .icon-selected {
        position: absolute;
        top: -4px;
        right: -4px;
      }
      img {
        width: 100%;
        border-radius: 6px;
      }
      &:nth-child(15n) {
        margin-right: 0;
      }
    }
  }
}
</style>