<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-27 16:03:30
 * @LastEditTime: 2024-04-03 14:11:34
 * @FilePath: \awx-ui\src\pages\appManage\addApp\appModal\appIcon\netWorkIcon.vue
-->
<template>
  <div class="system-icon">
    <a-input ref="userNameInput" v-model="iconName" @pressEnter="onSearch" placeholder="搜索图标关键字">
      <svg-icon icon-class="search" class="app-search" slot="prefix"></svg-icon>
    </a-input>
    <div class="icon-warp" v-if="iconList">
      <div v-for="(item, index) in iconList" :key="index" class="icon-item" @click="getIconBySelect(item)">
        <svg-icon v-if="'network-'+item === IconPath" class="icon-selected" icon-class="app-installed" />
        <icon :icon="item" style="font-size: 30px;" />
      </div>
    </div>
    <NoData v-else />
  </div>
</template>

<script>
import { Icon, getIcon, loadIcon, buildIcon } from "@iconify/vue2";
import NoData from "@/components/noData";
export default {
  data() {
    return {
      iconName: "",
      iconList: [],
      IconPath: "",
    };
  },
  props: {
    appDetail: Object,
  },
  components: {
    Icon,
    NoData,
  },
  watch: {
    "appDetail.icon": {
      handler(val) {
        if (val && this.IsNetWorkIcon(this.appDetail?.icon)) {
          this.IconPath = val;
          this.iconList.push(this.iconNetWorkName(this.appDetail?.icon));
          this.svg = getIcon(this.iconNetWorkName(this.appDetail?.icon)).body;
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName(name) {
      return global.utils.iconNetWorkName(name);
    },
    onSearch() {
      this.getAppIcon();
    },
    getIconBySelect(item) {
      this.IconPath = "network-" + item;
      this.$emit("getIcon", this.IconPath, "3");
    },
    getAppIcon() {
      fetch(
        `https://api.iconify.design/search?query=${this.iconName}&limit=1000`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json(); // 将响应体以 JSON 格式解析
        })
        .then((data) => {
          console.log(data); // 输出获取的数据
          this.iconList = data.icons;
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
        });
    },
  },
};
</script>
<style scoped lang='less'>
.system-icon {
  margin-top: 10px;
  .icon-warp {
    width: 100%;
    height: 172px;
    border: 1px dashed rgba(196, 202, 211, 1);
    border-top: none;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
    padding: 16px 0px 16px 16px;
    display: flex;
    flex-wrap: wrap;
    .icon-item {
      position: relative;
      cursor: pointer;
      height: 38px;
      margin: 0px 12px 0 0;
      width: 38px;
      min-width: 38px;
      background: #fff; // 这个是图标的颜色定义
      border-radius: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: center;
      box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
      .icon-selected {
        position: absolute;
        top: -4px;
        right: -4px;
      }
      img {
        width: 100%;
        border-radius: 6px;
      }
      &:nth-child(11n) {
        margin-right: 0;
      }
    }
  }
}
</style>