var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solid-icon"},[_c('div',{staticClass:"icon-warp"},[_c('div',{staticClass:"icon-item",style:({
      'background': _vm.colors.hex,
      'text-align': 'center',
    })},[_c('span',{style:({
      'color': '#fff',
      'font-size': _vm.app_name.length === 1 ? '22px' : '16px',
      'width': '100%'
    })},[_vm._v(_vm._s(_vm.app_name))])])]),_c('div',{staticClass:"color-picker"},[_c('twitter-picker',{attrs:{"defaultColors":_vm.colorOptions,"width":400},on:{"input":_vm.change},model:{value:(_vm.colors),callback:function ($$v) {_vm.colors=$$v},expression:"colors"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }